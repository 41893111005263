import React from "react";
import Header from "../components/Header"
import Layout from "../layouts/layout"
import HomeBanner from "../components/Banner/HomeBanner"
import SEOPage from "../components/SEOPage"
import ContentHeadingPara from "../components/ContentHeadingPara"
import Tool from '../components/Tool';
import "../ui-lib/styles/Main.scss"
import "../ui-lib/styles/toolsandbenefits.scss"

const dynosPrimary = () => {

    const banner = {
        heading: "Elevating Education for Grades 1 to 8",
        subHeading: "Our dynamic and versatile educational bundle includes a plethora of resources to support grade-wise learning.",
        button: "Login",
        image: "/primaryclass.png"
    }

    const dynotypeHead = {
        heading: "Dynos Comprehensive Learning Solution for Grades 1 to 8",
        subHeading: "Engage your learners in a dynamic, interactive educational environment with the Dynos Comprehensive Learning Solution. Specifically crafted for students in Grades 1 to 8, our integrated approach promotes holistic learning by blending traditional and digital methodologies. This all-inclusive package features premium learning materials tailored for Grades 1 to 8."
    }
    const toolsData = [
        {
            icon: '📚',
            title: 'Dynamic Digital & Print Books',
            description: 'Our package includes digital and in-print versions of 9 to 11 titles.'
        },
        {
            icon: '💻',
            title: 'Dynos Learning Management System',
            description: 'Our comprehensive LMS fosters the process of learning with integrated tools and resources.'
        },
        {
            icon: '📈',
            title: 'Integrated Lesson Plans',
            description: 'Enjoy the ease of teaching with our well- structured, curriculum-aligned lesson plans.'
        },
        {
            icon: '🎥',
            title: 'Topic-wise Videos & Presentations',
            description: 'Engaging multimedia resources designed to clarify and consolidate learning objectives.'
        },
        {
            icon: '📊',
            title: 'Assessments',
            description: 'Regular assessments to gauge students’ understanding and knowledge retention.'
        },
        {
            icon: '🎮',
            title: 'Educational Games',
            description: 'Fun and engaging games that make learning enjoyable and effective.'
        },
        {
            icon: '📝',
            title: 'Worksheets',
            description: 'Varied and interactive worksheets to reinforce learning.'
        },
        {
            icon: '📱',
            title: 'Dynamic Worksheet Generator',
            description: 'Create customized worksheets tailored to your students’ unique learning needs.'
        },
        {
            icon: '🧑‍🏫',
            title: 'In-person Implementation Workshops',
            description: 'We provide onsite workshops to streamline the process of integrating our resources into your existing curriculum.'
        },
        {
            icon: '🌐',
            title: 'Webinars for Teachers, Parents, and Students',
            description: 'Adequate guidance and training webinars to ensure optimal use of our learning solutions by all stakeholders.'
        }
    ];
        // Benefits data
        const benefitsData = [
            {
                "title": "Engaging Resources",
                "description": "Includes dynamic digital and print books, topic-wise videos, and educational games to make learning enjoyable."
            },
            {
                "title": "Integrated Tools",
                "description": "Features a comprehensive Learning Management System (LMS) with integrated lesson plans, assessments, and a dynamic worksheet generator."
            },
            {
                "title": "Support for Teachers and Parents",
                "description": "Offers in-person implementation workshops and webinars to ensure effective use of the resources."
            },
            {
                "title": "Holistic Learning",
                "description": "Combines traditional and digital methodologies for a well-rounded educational experience."
            }        ];
    return (
        <Layout>
            <SEOPage
                title="Dynos | Product | Interactive Digital Content for Schools."
                description="Dynos empowers schools to create engaging digital classrooms through interactive content, integrated lesson plans, and a user-friendly learning management system."
                image="/homeMetaImage.png"
            />
            <section className="primary-bg">
                <section className="main--wraper-primary">
                    <Header headerClass="headercolor" />
                    <section className="main--container">
                        <HomeBanner heading={banner.heading} subHeading={banner.subHeading} button={banner.button} image={banner.image} color="blue"/>
                    </section>
                </section>
                <section className="main--container">
                    <section className="align--center mt-120 zIndex">
                        <ContentHeadingPara heading={dynotypeHead.heading} subHeading={dynotypeHead.subHeading} />
                    </section>
                    <section>
                        <>    
                            <h3 style={{textAlign: "center", padding: "0 0 30px 0", fontSize: "28px", color: "#223f99"}}>Offering</h3>
                            <div className="tools">
                                {toolsData.map((tool, index) => (
                                <Tool key={index} tool={tool} />
                                ))}
                            </div>
                        </>
                        <div className="benefits-info">
                            <h3 style={{ textAlign: "center", padding: "0 0 30px 0", fontSize: "28px", color: "#223f99" }}>Benefits</h3>
                            <div className="benefit--wrap">
                                {
                                    benefitsData.map((benefitData, index) =>
                                        <Tool key={index} tool={benefitData} />
                                    )
                                }
                            </div>
                        </div>
                    </section>                    
                </section>
            </section>
        </Layout>
    )
}
export default dynosPrimary