import React from "react"

const Tool = ({tool}) => {
    return (
        <div className="tool">
            {/* <div className="icon">{tool.icon}</div> */}
            <div className="content">
                <h3>{tool.title}</h3>
                {Array.isArray(tool.description) ? (
                    <ul>
                        {tool.description.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                ) : (
                    <p>{tool.description}</p>
                )}
            </div>
        </div>
    )
}
export default Tool